<template>
  <div>
    <GeneralBlock
      :gui="gui"
      @settings-changed="$emit('settings-changed', $event)"
    />

    <EnvBlock
      :gui="gui"
      @settings-changed="$emit('settings-changed', $event)"
    />
    <!-- <IlluminationBlock
      v-bind:gui="gui"
      v-on:settings-changed="$emit('settings-changed', $event)"/>
    <ModelPositioningBlock
      v-bind:gui="gui"
      v-on:settings-changed="$emit('settings-changed', $event)"/> -->
  </div>
</template>
<script>
const GeneralBlock = () => import('./settings/GeneralBlock')
const EnvBlock = () => import('./settings/EnvBlock')

export default {
  name: 'SettingsPanel',

  components: {
    EnvBlock,
    GeneralBlock
  },

  props: {
    gui: {
      required: true,
      type: Object
    }
  }
}

</script>
